// /* You can add global styles to this file, and also import other style files */

@use '@angular/material' as mat;

// ------------------------------------------------------------------
// Tailwind
// ------------------------------------------------------------------
@tailwind base;
@tailwind components;
@tailwind utilities;

// ------------------------------------------------------------------
// Fonts
// ------------------------------------------------------------------
@font-face {
  font-family: 'circular';
  src: url('/assets/fonts/circular-black-italic.otf') format('opentype');
  font-weight: bolder;
  font-style: italic;
}

@font-face {
  font-family: 'circular';
  src: url('/assets/fonts/circular-bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'circular';
  src: url('/assets/fonts/circular-bold-italic.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'circular';
  src: url('/assets/fonts/circular-black.otf') format('opentype');
  font-weight: bolder;
  font-style: normal;
}

@font-face {
  font-family: 'circular';
  src: url('/assets/fonts/circular-book.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'circular';
  src: url('/assets/fonts/circular-book-italic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'circular';
  src: url('/assets/fonts/circular-medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'circular';
  src: url('/assets/fonts/circular-medium-italic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

* {
  font-family: 'circular', sans-serif;
}

// ------------------------------------------------------------------
// Redefine of angular material
// ------------------------------------------------------------------
@include mat.core();

// Checkbox
$my-primary: mat.define-palette(mat.$teal-palette, 300);
$my-accent: mat.define-palette(mat.$green-palette, 100);
$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent
    )
  )
);

@include mat.checkbox-theme($my-theme);
@include mat.radio-theme($my-theme);

.mdc-checkbox__background .mdc-checkbox__checkmark {
  @apply text-white #{!important};
}
.mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  @apply border-white #{!important};
}
.mdc-checkbox .mdc-checkbox__native-control[disabled]:checked ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[disabled]:indeterminate ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[data-indeterminate='true'][disabled] ~ .mdc-checkbox__background {
  border-color: #4db6ac !important;
  background-color: #4db6ac !important;
}

// ------------------------------------------------------------------
// Chart
// ------------------------------------------------------------------
.ngx-charts-tooltip-content {
  background: transparent !important;
  background-color: transparent !important;
}

.tooltip-caret {
  background: transparent !important;
  background-color: transparent !important;
}

.ngx-charts-tooltip-content.type-popover {
  background: transparent;
}

.ngx-charts-tooltip-content.type-popover .tooltip-caret.position-left {
  border: none;
}

.ngx-charts-tooltip-content.type-popover .tooltip-caret.position-top {
  border: none;
}

.ngx-charts-tooltip-content.type-popover .tooltip-caret.position-right {
  border: none;
}

.ngx-charts-tooltip-content.type-popover .tooltip-caret.position-bottom {
  border: none;
}

.ngx-charts-tooltip-content.type-tooltip {
  background: transparent;
}

.ngx-charts-tooltip-content.type-tooltip .tooltip-caret.position-left {
  border: none;
}

.ngx-charts-tooltip-content.type-tooltip .tooltip-caret.position-top {
  border-top: 7px solid rgba(255, 255, 255, 0.9) !important;
}

.ngx-charts-tooltip-content.type-tooltip .tooltip-caret.position-right {
  border: none;
}

.ngx-charts-tooltip-content.type-tooltip .tooltip-caret.position-bottom {
  border-bottom: 7px solid rgba(255, 255, 255, 0.9) !important;
}

.ngx-charts-tooltip-content .tooltip-label {
  padding: 0;
}

.ngx-charts-tooltip-content .tooltip-val {
  padding: 0;
}

// ------------------------------------------------------------------
// Global Classes
// ------------------------------------------------------------------

// Scroll bar
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
::-webkit-scrollbar-thumb {
  background: #c0c0c0;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #757575;
}

:root {
  color-scheme: light only;
}

:host {
  display: block;
}

// Body
body {
  @apply bg-[#fbfbfb];
}

// Tabs
.toolbar-tabs {
  .mat-mdc-tab {
    @apply min-w-40 p-0 mx-15 #{!important};
    .mdc-tab__text-label {
      @apply text-darkGray tracking-normal #{!important};
    }
    .mdc-tab-indicator__content--underline {
      @apply border-secondary border-b-3 #{!important};
    }
    .mdc-tab__ripple::before,
    .mat-ripple-element {
      @apply bg-transparent #{!important};
    }
  }
}
mat-tab-group.card-tabs {
  @apply h-48 border-b-3 border-b-lighterGray;

  .mat-tab-label {
    @apply p-0 min-w-90 mr-10 opacity-100;
  }
  .mat-mdc-tab .mdc-tab-indicator__content--underline {
    @apply border-t-3 border-t-secondary;
  }

  .mat-mdc-tab {
    flex-grow: 0 !important;
    &.mdc-tab__ripple {
      @apply hidden;
    }
    &:hover .mdc-tab__ripple::before {
      @apply hidden;
    }
    &.mdc-tab--active,
    &.mdc-tab--active:focus,
    &.mdc-tab--active:hover {
      & .mdc-tab__text-label {
        @apply text-darkGray;
      }
    }
  }
}

// Table
.table-container {
  @apply mb-50 shadow-card rounded-[4px] overflow-hidden;
  table {
    @apply w-full text-nowrap rounded-[4px];
  }
  th,
  td {
    @apply text-darkGray p-0;
    &:first-of-type {
      @apply pl-10 pr-10 border-l-3 border-l-transparent border-solid w-40;
    }
    &:last-of-type {
      @apply pr-24 w-48;
      mat-icon {
        @apply hidden;
      }
    }
  }
  td {
    @apply pr-10 whitespace-nowrap overflow-x-hidden text-ellipsis cursor-pointer h-[62px];
  }

  tr.selected,
  tr:not(.mat-mdc-header-row):not(.disabled):not(.mat-mdc-no-data-row):hover {
    @apply w-29 relative shadow-tr;

    td {
      &:first-of-type {
        @apply border-l-primary;
      }
      &:last-of-type {
        mat-icon {
          @apply block;
        }
      }
    }
  }

  tr.disabled td {
    @apply cursor-default;
  }

  tr.mat-mdc-no-data-row td {
    @apply border-solid border-l-3 border-transparent pl-24 max-w-none text-center cursor-default;

    height: 496px;

    img {
      @apply inline w-150;
    }
  }
}
.paginator {
  @apply w-full flex justify-between items-center pl-24 text-12 bg-white text-darkGray h-56 #{!important};
  .paginator-total {
    @apply whitespace-nowrap overflow-x-hidden text-ellipsis table-cell;
  }
  mat-paginator {
    @apply flex-1 text-darkGray #{!important};
    .mat-mdc-paginator-range-label {
      @apply whitespace-nowrap #{!important};
    }
  }
}

// Dialog
.cartwheel-base-dialog {
  @apply static phone:absolute desktop:static left-0 w-dvw max-w-none desktop:w-full desktop:max-w-[80vw] h-dvh desktop:h-auto desktop:flex desktop:justify-center #{!important};

  .mat-mdc-dialog-container {
    @apply p-0 m-0 border-0 max-w-[609px] w-full overflow-x-hidden rounded-tl rounded-tr #{!important};
  }

  .cartwheel-base-dialog-scroll-content {
    @apply overflow-y-auto overflow-x-hidden desktop:max-h-[calc(80vh-90px-85px)];
  }

  .cartwheel-base-dialog-footer {
    @apply flex flex-wrap py-20 desktop:border-t border-solid gap-x-20 gap-y-5 border-lighterGray;
  }

  .dialog-body {
    @apply flex flex-col justify-center w-full;

    .dialog-header {
      @apply relative flex items-center justify-center bg-secondary rounded-t-[4px] h-[14vh] tablet:h-90;

      .dialog-close-btn {
        @apply absolute right-10 top-10;

        * {
          @apply text-white;
        }
      }
      .dialog-header-text {
        @apply font-medium text-white self-center my-0 mx-auto text-center h-28 w-450 text-18 tablet:text-20;
      }
    }
  }
}

.team-selector-dialog {
  @apply h-full fixed right-0 w-full phoneLandscape:w-auto max-w-[unset] max-h-[unset] #{!important};
}

.confirm-dialog {
  @apply relative p-24 flex flex-col items-center;

  .header-text {
    @apply pt-20 w-full max-w-400 text-18 phone:text-24 font-bold leading-[1.5] text-center text-darkerGray;
  }
  .dialog-content {
    @apply flex justify-center w-full gap-10 mt-28 px-15;
  }
  .dialog-close-btn {
    @apply absolute right-10 top-10;
  }
}

// status badge
.status-badge {
  @apply px-15 py-3 flex border-1 items-center rounded-full text-center text-12 leading-1 h-20 text-nowrap;

  &.primary {
    @apply text-primary border-primary;
  }
  &.darkGray {
    @apply text-darkerGray border-darkerGray;
  }
  &.danger {
    @apply text-danger border-danger;
  }
}

// button
.detailed-save-btn {
  & > button {
    @apply rounded-md px-30 #{!important};
  }
}
.no-ripple {
  & .mat-mdc-button-persistent-ripple,
  & .mat-ripple {
    @apply hidden #{!important};
  }
}

// pagination
.pagination-wrapper {
  @apply flex justify-center gap-3 py-20;

  .page-arrow-btn {
    @apply h-37 w-37;
    & > button {
      @apply w-37 h-37 flex rounded-sm #{!important};

      .mat-mdc-button-persistent-ripple {
        @apply rounded-none;
      }
    }
    & * {
      @apply text-darkGray;
    }
  }
  .page-btn-group {
    @apply flex gap-1;
    cartwheel-button {
      @apply h-37 w-37;
      & > button {
        @apply min-w-37 min-h-37 flex rounded-sm #{!important};
      }
    }
  }
}
// Accordion
.accordion {
  @apply block font-bold text-left mb-30 text-middleGray;

  .accordion-item {
    @apply block border border-solid border-lighterGray border-b-0 cursor-default;

    &:first-child {
      @apply rounded-t;
    }

    &:last-child {
      @apply rounded-bl border-b-1 border-solid border-b-lighterGray;
    }

    .accordion-item-header {
      @apply flex items-center justify-between text-12;
    }

    .accordion-item-header,
    .accordion-item-body {
      @apply p-16;
    }

    .accordion-item-header:hover {
      @apply cursor-pointer bg-slate-50;
    }
  }
}

.card-title {
  @apply text-14 font-bold text-left text-darkerGray opacity-70 uppercase mb-10;
}
